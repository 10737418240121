import React, { useState } from 'react';
import 'katex/dist/katex.min.css';

const monthData = {
  January: 125.26,
  February: 135.82,
  March: 146.38,
  April: 156.93,
  May: 167.49,
  June: 178.05,
  July: 188.61,
  August: 199.17,
  September: 209.73,
  October: 220.29,
  November: 230.85,
  December: 241.41,
};

const reports = {
  January: `Monthly Blood Supply and Demand Report
  Month: January 2025
  1. Executive Summary
      • Blood Supply: 405 units
      • Blood Demand: 533 units
      • Overall Balance: Deficit of 128 units
      • Key Highlights:
          ◦ Shortages caused by low donor turnout during the post-holiday period.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 405
          ◦ Collection methods:
              ▪ Fixed Centers: 280 units (69%)
              ▪ Mobile Units and Camps: 125 units (31%)
  3. Recommendations:
      1. Boost Donor Recruitment:
          ◦ Implement targeted post-holiday donation campaigns to encourage participation.
      2. Emergency Reserves:
          ◦ Prepare for seasonal deficits by redistributing surplus from other months.`,
  February: `Monthly Blood Supply and Demand Report
  Month: February 2025
  1. Executive Summary
      • Blood Supply: 408 units
      • Blood Demand: 515 units
      • Overall Balance: Deficit of 107 units
      • Key Highlights:
          ◦ Slight improvement in supply (+3 units compared to January).
          ◦ Demand remained high due to winter-related illnesses and emergencies.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 408
          ◦ Collection methods:
              ▪ Fixed Centers: 290 units (71%)
              ▪ Mobile Units and Camps: 118 units (29%)
  3. Recommendations:
      1. Enhance Mobile Units:
          ◦ Expand coverage of mobile donation units to underserved areas to increase collection.
      2. Partnerships:
          ◦ Collaborate with local organizations to boost donations during this season.`,
  March: `Monthly Blood Supply and Demand Report
  Month: March 2025
  1. Executive Summary
      • Blood Supply: 429 units
      • Blood Demand: 470 units
      • Overall Balance: Deficit of 41 units
      • Key Observations:
          ◦ Increased donor turnout post-winter helped reduce deficit.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 429
          ◦ Collection methods:
              ▪ Fixed Centers: 300 units (70%)
              ▪ Mobile Units and Camps: 129 units (30%)
  3. Recommendations:
      1. Expand workplace and university donor outreach.
      2. Maintain momentum through donor retention programs.`,
  April: `Monthly Blood Supply and Demand Report
  Month: April 2025
  1. Executive Summary
      • Blood Supply: 435 units
      • Blood Demand: 470 units
      • Overall Balance: Deficit of 35 units
      • Key Observations:
          ◦ Improved donor response due to early spring campaigns.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 435
          ◦ Collection methods:
              ▪ Fixed Centers: 310 units (71%)
              ▪ Mobile Units and Camps: 125 units (29%)
  3. Recommendations:
      1. Host additional drives in densely populated areas.
      2. Strengthen partnerships with community organizations.`,
  May: `Monthly Blood Supply and Demand Report
  Month: May 2025
  1. Executive Summary
      • Blood Supply: 420 units
      • Blood Demand: 425 units
      • Overall Balance: Deficit of 5 units
      • Key Observations:
          ◦ Narrowing of the deficit due to strong public awareness campaigns.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 420
          ◦ Collection methods:
              ▪ Fixed Centers: 295 units (70%)
              ▪ Mobile Units and Camps: 125 units (30%)
  3. Recommendations:
      1. Maintain donor engagement through incentives.
      2. Focus on O-negative and A-negative donor recruitment.`,
  June: `Monthly Blood Supply and Demand Report
  Month: June 2025
  1. Executive Summary
      • Blood Supply: 441 units
      • Blood Demand: 404 units
      • Overall Balance: Surplus of 37 units
      • Key Observations:
          ◦ Surplus driven by successful summer donation campaigns.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 441
          ◦ Collection methods:
              ▪ Fixed Centers: 320 units (72%)
              ▪ Mobile Units and Camps: 121 units (28%)
  3. Recommendations:
      1. Allocate surplus for future high-demand months.
      2. Encourage donations from first-time donors.`,
  July: `Monthly Blood Supply and Demand Report
  Month: July 2025
  1. Executive Summary
      • Blood Supply: 550 units
      • Blood Demand: 430 units
      • Overall Balance: Surplus of 120 units
      • Key Observations:
          ◦ Peak surplus month due to large-scale summer donation drives.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 550
          ◦ Collection methods:
              ▪ Fixed Centers: 350 units (64%)
              ▪ Mobile Units and Camps: 200 units (36%)
  3. Recommendations:
      1. Store excess units for winter shortages.
      2. Continue to promote summer campaigns in high-donor areas.`,
  August: `Monthly Blood Supply and Demand Report
  Month: August 2025
  1. Executive Summary
      • Blood Supply: 489 units
      • Blood Demand: 433 units
      • Overall Balance: Surplus of 56 units
      • Key Observations:
          ◦ Continued strong supply with consistent donations from summer drives.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 489
          ◦ Collection methods:
              ▪ Fixed Centers: 340 units (70%)
              ▪ Mobile Units and Camps: 149 units (30%)
  3. Recommendations:
      1. Build reserves from surplus.
      2. Continue outreach to maintain donation rates.`,
  September: `Monthly Blood Supply and Demand Report
  Month: September 2025
  1. Executive Summary
      • Blood Supply: 433 units
      • Blood Demand: 445 units
      • Overall Balance: Deficit of 12 units
      • Key Observations:
          ◦ Slight deficit as donation rates tapered off post-summer.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 433
          ◦ Collection methods:
              ▪ Fixed Centers: 310 units (72%)
              ▪ Mobile Units and Camps: 123 units (28%)
  3. Recommendations:
      1. Increase donor engagement as summer drives conclude.
      2. Improve collaboration with healthcare facilities to predict demand.`,
  October: `Monthly Blood Supply and Demand Report
  Month: October 2025
  1. Executive Summary
      • Blood Supply: 436 units
      • Blood Demand: 476 units
      • Overall Balance: Deficit of 40 units
      • Key Observations:
          ◦ Widened deficit due to increasing demand for chronic illness treatments.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 436
          ◦ Collection methods:
              ▪ Fixed Centers: 315 units (72%)
              ▪ Mobile Units and Camps: 121 units (28%)
  3. Recommendations:
      1. Plan donation drives around holidays.
      2. Target chronic illness donors to ensure year-end supply.`,
  November: `Monthly Blood Supply and Demand Report
  Month: November 2025
  1. Executive Summary
      • Blood Supply: 422 units
      • Blood Demand: 540 units
      • Overall Balance: Deficit of 118 units
      • Key Observations:
          ◦ Significant deficit due to increased holiday season demand.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 422
          ◦ Collection methods:
              ▪ Fixed Centers: 290 units (69%)
              ▪ Mobile Units and Camps: 132 units (31%)
  3. Recommendations:
      1. Launch emergency drives targeting corporate donors.
      2. Strengthen outreach to underserved regions.`,
  December: `Monthly Blood Supply and Demand Report
  Month: December 2025
  1. Executive Summary
      • Blood Supply: 400 units
      • Blood Demand: 550 units
      • Overall Balance: Deficit of 150 units
      • Key Observations:
          ◦ Highest deficit of the year, as demand far exceeded supply.
  2. Blood Collection Overview
      • Blood Collected:
          ◦ Total Units: 400
          ◦ Collection methods:
              ▪ Fixed Centers: 280 units (70%)
              ▪ Mobile Units and Camps: 120 units (30%)
  3. Recommendations:
      1. Organize winter drives targeting repeat donors.
      2. Provide incentives like free health checkups for holiday donors.`
};


const annualReport = `The Blood Demand Prediction was utilized for the purpose of using historical data to predict the demand for blood on a month-to-month basis and visualizing the driving forces of not only historical trends but also the forecasts.

<h1>Annual Blood Supply and Demand Report</h1>
Year: 2025
<img src="1.png" alt="Annual blood supply and demand trends">

<b>1. Executive Summary</b>
The blood supply and demand analysis for 2025 reveals significant seasonal variations in collection and utilization. While certain months experienced surpluses, others faced deficits, with the largest shortages occurring in the winter months. The report highlights key trends, critical shortages, and actionable recommendations to ensure a more balanced supply in the coming year.
    • Total Blood Supply: 4,789 units
    • Total Blood Demand: 5,221 units
    • Overall Balance: Deficit of 432 units
    • Key Highlights:
        ◦ Surplus Months: June (+37 units), July (+120 units), and August (+56 units).
        ◦ Deficit Months: Most severe shortages in November (-118 units) and December (-150 units).
        ◦ Demand consistently exceeded supply by an average of 36 units per month.


<b>2. Monthly Breakdown</b>
<img src="2.png" alt="Annual blood supply and demand trends">

<b>3. Blood Collection Overview</b>
    • Annual Blood Collection:
        ◦ Fixed Centers: 3,230 units (67%)
        ◦ Mobile Units and Camps: 1,559 units (33%)


<b>4. Key Observations and Trends</b>
    1. Deficit Peaks:
        ◦ January, November, and December experienced severe shortages due to increased seasonal demand and lower donor turnout during winter and holidays.
    2. Surplus Months:
        ◦ June, July, and August recorded surpluses driven by successful summer donation drives.
    3. Seasonal Demand Variations:
        ◦ Demand peaked during winter (December) and decreased during summer months.

<b>5. Recommendations</b>
    1. Boost Donations During Winter:
        ◦ Targeted winter donation campaigns should focus on November and December.
        ◦ Provide donor incentives such as free health checkups, recognition, and small rewards.
    2. Utilize Surplus Months:
        ◦ Surpluses from June, July, and August should be stored as emergency reserves for high-demand months.
    3. Expand Mobile Unit Coverage:
        ◦ Increase the number of mobile collection drives in rural and underserved areas, especially during deficit months.
    4. Enhance Public Awareness Campaigns:
        ◦ Educate the public about the year-round need for blood donations, focusing on critical periods like holidays and winter.
    5. Strengthen Donor Retention Programs:
        ◦ Build a database of regular donors and implement follow-up programs to encourage repeat donations.
    6. Collaborate with Hospitals and Organizations:
        ◦ Partner with hospitals to predict seasonal demand trends.
        ◦ Work with corporate and community organizations to expand donor outreach.`;

const BloodInventoryChart = () => {
  const [selectedMonth, setSelectedMonth] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectionChange = (event) => {
    const month = event.target.value;
    setIsLoading(true);
    setSelectedMonth(month);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Simulate loading
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-4">
      <h1 className="text-3xl font-bold text-center mb-6">Blood Orders Statistics</h1>

 {/* Monthly Selection */}
 <div className="bg-gray-100 p-6 rounded-lg shadow-md mb-6">
          <label className="block text-lg font-semibold mb-2" htmlFor="monthSelect">
            Select Month of 2025 to be Predicted: (DEMAND)
          </label>
          <select
            id="monthSelect"
            value={selectedMonth}
            onChange={handleSelectionChange}
            className="w-full p-2 border rounded-md"
          >
            <option value="" disabled>
              -- Select a Month --
            </option>
            {Object.keys(monthData).map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </select>

          <div className="mt-6 text-center">
            {isLoading ? (
              <p className="text-lg font-semibold text-blue-500">Predicting demand...</p>
            ) : selectedMonth ? (
              <div>
                <p className="text-lg font-semibold text-gray-700 mb-4">
                  {`Selected Month: ${selectedMonth}, Value: ${monthData[selectedMonth]}`}
                </p>
                <pre className="text-left text-sm bg-gray-100 p-4 rounded shadow-md whitespace-pre-wrap">
                  {reports[selectedMonth]}
                </pre>
              </div>
            ) : (
              <p className="text-lg font-semibold text-gray-500">
                Please select a month to see the details.
              </p>
            )}
          </div>
        </div>



      <div className="bg-white p-6 rounded-lg shadow-lg">
        {/* Annual Report */}
        <div className="bg-blue-50 p-6 rounded-lg shadow-md mb-6">
  <h2 className="text-2xl font-semibold mb-4">Annual Blood Supply and Demand Report</h2>
  <div
    className="text-left text-sm bg-gray-100 p-4 rounded shadow-md whitespace-pre-wrap"
    dangerouslySetInnerHTML={{ __html: annualReport }}
  ></div>
</div>


       
      </div>
    </div>
  );
};

export default BloodInventoryChart;
